import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import { CiHeart } from "react-icons/ci";
import { BiCart } from "react-icons/bi";
import { CiMenuFries } from "react-icons/ci";
import axios from "axios";
import { AppContext } from "./App";
import whitelogo from "./assets/whitelogo.png";
import darklogo from "./assets/darklogo.png";
import { Profile } from "./Profile/Profile";
import { DarkModeContext } from "./DarkModeContext";
import { MdDarkMode } from "react-icons/md";
import { MdLightMode } from "react-icons/md";
import { useCart } from "./components/CartProvider";

export const Nav = () => {
  const [cartItems, setCartItems] = useState([]);
  const [user, setUser] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const { iconClicked, setIconClicked, url } = useContext(AppContext);
  const [navbarColor, setNavbarColor] = useState("bg-white");
  const [textColor, setTextColor] = useState("text-black");
  const loggedUser = localStorage.getItem("logged");
  const userIds = useMemo(() => new Set(), []);
  const [userFavoritesCount, setUserFavoritesCount] = useState(0);
  const {addItem, deleteItem, increaseItem, decreaseItem} = useCart()
  const { toggleDarkMode, isDarkMode } = useContext(DarkModeContext);

  const fetchCartItems = useCallback(async () => {
    if (loggedUser) {
      try {
        const response = await axios.get(`${url}api/cart/${loggedUser}`);
        setCartItems(response.data);
      } catch (error) {
        console.error("Error fetching cart items:", error);
      }
    }
  }, [loggedUser, url, addItem, deleteItem, increaseItem, decreaseItem ]);

  const fetchUser = useCallback(async () => {
    try {
      const response = await axios.get(`${url}api/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  }, [url]);

  // const fetchFavorites = useCallback(async () => {
  //   try {
  //     const response = await axios.get(`${url}api/fav/${loggedUser}`);
  //     setFavorites(response.data);
  //   } catch (error) {
  //     console.error("Error fetching favorites:", error);
  //   }
  // }, [loggedUser, url, favorites]);

  useEffect(() => {
    fetchCartItems();
    fetchUser();
    // fetchFavorites();
  }, [fetchCartItems, fetchUser]);

  // useEffect(() => {
  //   favorites.forEach((fav) => {
  //     if (fav.current_user === loggedUser) {
  //       userIds.add(fav.id); // Use a unique identifier of each item (e.g., item ID) to track unique items
  //     }
  //   });

  //   setUserFavoritesCount(userIds.size);
  // }, [favorites, loggedUser, userIds]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarColor("bg-white");
        setTextColor("text-black");
      } else {
        setNavbarColor("bg-white");
        setTextColor("text-black");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={
        isDarkMode
          ? `dark:bg-[#252525] drop-shadow dark:text-white sticky top-0 w-full z-[60] border-gray-200 ${navbarColor}`
          : `sticky drop-shadow top-0 w-full z-[60] border-gray-200 ${navbarColor}`
      }
    >
      <Profile />
      <div className="space-x-4 flex items-center justify-between mx-auto p-4">
        <a href="/">
          <img className="dark:hidden w-20" src={darklogo} alt="Logo" />
          <img className="hidden dark:block w-20" src={whitelogo} alt="Logo" />
        </a>

        {!loggedUser && (
          <div className="hidden w-full lg:block lg:w-auto" id="navbar-default">
            <ul className="flex items-center space-x-4">
              <li>
                <a href="/admin">Login</a>
              </li>
              <li>
                <a href="/faq">FAQs</a>
              </li>
              <li>
                <a href="/admin">
                  <button className="bg-black dark:bg-white dark:text-black px-5 py-2.5 shrink-0 rounded-xl text-white">
                    Create free account
                  </button>
                </a>
              </li>
            </ul>
          </div>
        )}

        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <button className="dark:hidden text-xl" onClick={toggleDarkMode}>
              <MdDarkMode className="dark:hidden text-xl" />
            </button>
            <button
              className="hidden dark:block text-xl"
              onClick={toggleDarkMode}
            >
              <MdLightMode className="hidden dark:block text-xl" />
            </button>
          </div>
          <a href="/cart" className="relative">
            <BiCart className={`dark:text-gray-200 text-2xl ${textColor}`} />
            <div className="dark:bg-gray-200 dark:text-black flex items-center p-1 justify-center absolute -top-4 -right-2 bg-black text-white w-5 h-5 rounded-full">
              {cartItems.length}
            </div>
          </a>
          {/* <a href="/fav" className="relative">
            <CiHeart
              className={`text-black dark:text-gray-200 text-2xl ${textColor}`}
            />
            <div className="dark:bg-gray-200 dark:text-black flex items-center p-1 justify-center absolute -top-4 -right-2 bg-black text-white w-5 h-5 rounded-full">
              {favorites.length}
            </div>
          </a> */}
          {loggedUser ? (
            <button
              onClick={() => setIconClicked(!iconClicked)}
              className="dark:border-gray-200 border-2 rounded-full p-4 w-5 h-5 flex justify-center items-center"
            >
              {loggedUser.charAt(0).toLocaleUpperCase()}
            </button>
          ) : (
            <CiMenuFries
              onClick={() => setIconClicked(!iconClicked)}
              className={`text-black dark:text-gray-200 cursor-pointer text-2xl ${textColor}`}
            />
          )}
        </div>
      </div>
    </nav>
  );
};
