const plansData = [
    {
      type: "Personal",
      pricing: "9",
      pricingYearly: "60",
      description: "Perfect for freelancers or any other design enthusiast",
      details: [
        "Unlimited mobile & web flows",
        "Discover latest apps, screens and flows",
        // "Download design file in Figma",
        // "UX process explained",
        "Personal",
        "Cancel anytime"
      ],
      action: "Get started",
      bg: 'border-2 border-green-500',
      price_lookup: 'price_1PC4EcGxovixmg99Bo4iILbv'
    },
    {
        type: "Business",
        pricing: "20",
        pricingYearly: "99",
        description: "Perfect for any design teams, agencies, and enterprises.",
        details: [
            "Unlimited mobile & web flows",
            "Discover latest apps, screens and flows",
            // "Download design file in Figma",
            "Download coded files in Html, CSS React, Ionic",
            // "UX process explained",
            "Cancel anytime"
        ],
        action: "Get started",
        bg: 'border',
        price_lookup: 'prod_Q28t5QA6VPHdos'
    },
];
  
  export default plansData