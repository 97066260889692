import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Images } from "./Images";
import { Descriptions } from "./Descriptions";
import { Price } from "./Price";
import { WriteReview } from "./WriteReview";
import data from "../data.js";
import { AppContext } from "../App";
import axios from "axios";
import { useCart } from "../components/CartProvider.js";
export const Detailspage = () => {
  const [items, setItems] = useState(null);
  const { id } = useParams();
  const {addItem} = useCart()

  useEffect(() => {
    const filteredData = data.filter((item) => item.id == id);
    setItems(filteredData);
  }, []);
  return (
    <div className="px-4 md:px-10 py-6 max-w-7xl mx-auto">
      {items?.map((item) => (
        <>
          <div className=" flex flex-col lg:flex-row lg:space-x-4 justify-between gap-4">
            <Images data={item} />
            <Descriptions productId={id} data={item}/>
            <Price  addItem={addItem} data={item} />
          </div>
          <WriteReview productId={id}/>
        </>
      ))}
    </div>
  );
};
