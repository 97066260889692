import React, { useContext, useState } from "react";
import axios from "axios";
import { AppContext } from "../App";

export const AddItem = ({ currentUser }) => {
  const { url } = useContext(AppContext);
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    description: "",
    image: null,
    category: "", // Added category field
  });

  const handleChange = (e) => {
    if (e.target.name === "image") {
      setFormData({
        ...formData,
        image: e.target.files[0],
      });
    } else {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, price, image, description, category } = formData;
    try {
      const formData = new FormData();
      formData.append("id", localStorage.getItem('logged'));
      formData.append("name", name);
      formData.append("price", price);
      formData.append("description", description);
      formData.append("category", category); // Append category to FormData
      formData.append("image-upload", image);

      const response = await axios.post(`${url}api/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Item added successfully:", response.data);
      setSuccess(response.data);
      setTimeout(() => {
        setSuccess("");
      }, 2000);
      // Reset form fields after successful submission
      setFormData({

        name: "",
        price: "",
        description: "",
        image: null,
        category: "", // Clear category after submission
      });

      // Handle success (e.g., show a success message to the user)
    } catch (error) {
      console.error("Error adding item:", error.response.data);
      setErr(error.response.data);
      // Handle error (e.g., display error message to the user)
      setTimeout(() => {
        setErr("");
      }, 2000);
    }
  };

  return (
    <div className="lg:w-1/2">
      <form className="" onSubmit={handleSubmit}>
        <p>Welcome, {localStorage.getItem("logged")}</p>
        {err && (
          <p className="text-red-500 w-full bg-white z-[50000] text-center text-xl py-2.5 drop-shadow-2xl fixed top-10 left-0 transition-all duration-300 ease-in-out">
            {err.message}
          </p>
        )}
        {success && (
          <p className="text-green-500 w-full bg-white z-[50000] text-center text-xl py-2.5 drop-shadow-2xl fixed top-10 left-0 transition-all duration-300 ease-in-out">
            {success.message}
          </p>
        )}
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-white">
            Title
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 dark:bg-transparent py-3 dark:text-white dark:border dark:border-[#3d3d3d] px-4"
            required
            maxLength="100"
          />
          <p className="font-[200] text-gray-400 text-sm">Word count: {formData.name.length}</p>
        </div>
        <div className="mb-4">
          <label htmlFor="price" className="block text-sm font-medium text-gray-700 dark:text-white">
            Price
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 dark:bg-transparent py-3 dark:text-white dark:border dark:border-[#3d3d3d] px-4"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-white">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 dark:bg-transparent py-3 dark:text-white dark:border dark:border-[#3d3d3d] px-4"
            required
            maxLength="5000"
          />
          <p className="font-[200] text-gray-400 text-sm">Word count: {formData.description.length}</p>
        </div>
        <div className="mb-4">
          <label htmlFor="image" className="block text-sm font-medium text-gray-700 dark:text-white">
            Image
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 dark:bg-transparent py-3 dark:text-white dark:border dark:border-[#3d3d3d]"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="category" className="block text-sm font-medium text-gray-700 dark:text-white">
            Category
          </label>
          <select
            id="category"
            name="category"
            value={formData.category}
            onChange={handleChange}
            className="mt-1 p-2 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-red-500 focus:border-red-500 dark:bg-transparent py-3 dark:text-white dark:border dark:border-[#3d3d3d]"
            required
          >
            <option value="">Select a category</option>
            <option value="New">New</option>
            <option value="Asian">Asian</option>
            <option value="Latino">Latino</option>
            {/* Add additional category options as needed */}
          </select>
        </div>
        <button
          type="submit"
          className="w-full bg-black text-white py-2 px-4 rounded-xl hover:bg-gray-800 focus:outline-none focus:bg-red-600"
        >
          Add Item
        </button>
      </form>
    </div>
  );
};

export default AddItem;
