import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { CartContext } from "../App";
import { Sidebar } from "./Sidebar";
import { AddItem } from "./AddItem";
import { Users } from "./Users";
import { Orders } from "./Orders";
import { Dashboard } from "./Dashboard";
import { Account } from "./Account";
import { Products } from "./Products";

export const AdminDashboard = ({ currentUser }) => {
  const [showCurrentTab, setShowCurrentTab] = useState(1);
  const accountSettings = [
    { id: 6, currentPage: <Products /> },
    { id: 5, currentPage: <Account /> },
    { id: 4, currentPage: <Orders /> },
    { id: 3, currentPage: <Users /> },
    { id: 2, currentPage: <AddItem /> },
    { id: 1, currentPage: <Dashboard /> },
  ];
  return (
    <div className="flex w-full py-4 lg:py-0">
      <Sidebar
        setShowCurrentTab={setShowCurrentTab}
        currentColor={"bg-gray-500 text-white dark:bg-gray-100 dark:text-black"}
        currentTab={showCurrentTab}
        />
  
      <div className="pl-24 md:pl-64 bg-white dark:bg-[#252525] min-h-screen w-full py-4 px-4">
      {accountSettings.map((accountSetting) => {
        if (showCurrentTab === accountSetting.id) {
          return accountSetting.currentPage;
        }
      })}
      </div>
    </div>
  );
};
