import axios, { all } from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../App';
import { RiDeleteBinLine } from 'react-icons/ri';

export const Products = () => {
    const { url } = useContext(AppContext);
    const [allItems, setAllItems] = useState([]);
    useEffect(() => {
        const fetchAllItems = async () => {
          try {
            const response = await axios.get(`${url}api/allItems`);
            setAllItems(response.data);
          } catch (error) {
            console.error("Error fetching all items:", error);
          }
        };
    
        fetchAllItems();
      }, [allItems, url]);

      const handleDelet = async (item) => {
        try {
            await axios.post(`${url}api/delete`, {item: item});
        } catch (error) {
            console.error("Error delete item:", error);
        }
      }
  return (
    <div className='h-screen'>
        <h1>My Products</h1>
        <div className="py-4 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {
            allItems.map(item => (
                <div className='relative group' key={item._id}>
                    <img className='rounded w-full h-40 object-cover' src={item.imageUrl} alt={item.name} />
                    <button onClick={() => handleDelet(item._id)} className='block group-hover:block md:hidden absolute top-0 right-0 bg-white text-xl rounded'>
                        <RiDeleteBinLine/>
                    </button>
                </div>
            ))
            }
        </div>
    </div>
  )
}
