import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { RiDeleteBinLine } from "react-icons/ri";
import { useFavorite } from "../components/FavoriteProvider";

export const Favorite = () => {
  const { url } = useContext(AppContext);
  const { clearFav, deletFavItem } = useFavorite();
  const [favorites, setFavorites] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`${url}api/fav`);
      setFavorites(response.data);
    };

    fetchData();
  }, [url]);
  return (
    <div className="p-4">
      {favorites.length > 0 ? (
        <>
          {favorites.map((fav) => {
            if (localStorage.getItem("logged") === fav.current_user) {
              return (
                <>
                  <h1 className=" text-2xl font-semibold">
                    My favorites ({favorites.length})
                  </h1>
                  <button
                    className=" text-red-500"
                    onClick={() => clearFav(localStorage.getItem("logged"))}
                  >
                    Clear Favorite
                  </button>
                </>
              );
            }
          })}
          <div className="p-4 grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {favorites.map((item) => {
              if (localStorage.getItem("logged") == item.current_user) {
                return (
                  <div
                    key={item._id}
                    className="flex flex-col md:flex-row md:space-x-4 w-full  mb-4 relative"
                  >
                    <img
                      className="w-full md:w-40 lg:w-80 object-contain rounded"
                      src={item.imageUrl}
                      alt=""
                    />
                    <div>
                      <h1 className="font-semibold text-2xl">{item.name}</h1>
                      <p className="text-xl">${item.price}</p>
                    </div>
                    <RiDeleteBinLine
                      className="bg-white rounded  p-2  text-3xl cursor-pointer absolute top-4 right-4"
                      onClick={() => deletFavItem(item._id)}
                    />
                  </div>
                );
              }
            })}
          </div>
        </>
      ) : (
        <div className="text-center">
          {/* <IoBagOutline className="text-[180px] w-fit mx-auto"/> */}
          <h1 className="text-3xl font-bold py-4 text-gray-600">
            Your favorite is empty
          </h1>
          <p className="text-gray-400">
            You have no items in your favorites cart.
          </p>
          <p className="text-gray-400 pb-6">Let's go buy something!</p>
          <a href="/" className="bg-black rounded-full py-2.5 px-5 text-white">
            View Menu
          </a>
        </div>
      )}
    </div>
  );
};
