import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const url = localStorage.getItem("url");

  const fetchCartItems = async () => {
    try {
      const response = await axios.get(`${url}api/cart/${localStorage.getItem("logged")}`);
      setCartItems(response.data);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, [url]);

  const addItem = async (item, user) => {
    if (!localStorage.getItem("logged")) {
      toast.error("Please Login!");
      return;
    }

    try {
      const response = await axios.post(`${url}api/cart`, {
        item: item,
        current_user: user,
      });
      setCartItems((prevItems) => [...prevItems, response.data]);
      toast.success("Item added to cart!");
    } catch (error) {
      console.error(error);
      toast.error("Error adding item to cart.");
    }
  };

  const increaseItem = async (id) => {
    try {
      const response = await axios.put(`${url}api/cart/increase/${id}`);
      setCartItems((prevItems) =>
        prevItems.map((item) => (item._id === response.data._id ? response.data : item))
      );
      toast.success("Item quantity increased!");
    } catch (error) {
      console.error(error);
      toast.error("Error increasing item quantity.");
    }
  };

  const decreaseItem = async (id) => {
    try {
      const response = await axios.put(`${url}api/cart/decrease/${id}`);
      if (response.data.quantity <= 0) {
        await deleteItem(id);
      } else {
        setCartItems((prevItems) =>
          prevItems.map((item) => (item._id === response.data._id ? response.data : item))
        );
      }
      toast.success("Item quantity decreased!");
    } catch (error) {
      console.error(error);
      toast.error("Error decreasing item quantity.");
    }
  };

  const deleteItem = async (id) => {
    try {
      await axios.delete(`${url}api/cart/${id}`);
      setCartItems((prevItems) => prevItems.filter((item) => item._id !== id));
      toast.success("Item removed from cart!");
    } catch (error) {
      console.error(error);
      toast.error("Error removing item from cart.");
    }
  };

  const clearCart = async (email) => {
    try {
      await axios.delete(`${url}api/cart/clear/${email}`);
      setCartItems([]);
      toast.success("Cart cleared!");
    } catch (error) {
      console.error("Error clearing cart:", error);
      toast.error("Error clearing cart.");
    }
  };

  return (
    <CartContext.Provider value={{ cartItems, addItem, increaseItem, decreaseItem, deleteItem, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
