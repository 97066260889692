import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";

export const Users = () => {
  const { url } = useContext(AppContext);
  const [user, setUser] = useState([]);
  const [role, setRole] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    const getUser = async () => {
      try {
        const user = await axios.get(`${url}api/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUser(user.data);
        console.log(user.data);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    getUser();
  }, []);

  const updateRole = async (email, role) => {
    try {
      const update = await axios.post(`${url}api/updaterole`, {
        email: email,
        role: role,
      });
    } catch (error) {
      // Handle errors
      console.error("Error fetching data:", error);
    }
  };
  return (
    <div className="w-full xl:w-full px-2 h-screen py-4 relative overflow-x-auto border dark:border-[#3d3d3d] rounded-xl">
      <table className="dark:border-[#3D3D3D]   rtl:text-right w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-transparent dark:text-white">
          <tr>
            <th scope="col" className="px-6 py-3">
              User Name
            </th>
            <th scope="col" className="px-6 py-3">
              User ID
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3">
              Role
            </th>
          </tr>
        </thead>
        <tbody>
          {user?.map((user) => (
            <tr key={user._id} className="bg-white border-b dark:border-b-[#3d3d3d] dark:bg-transparent dark:text-white">
              <td className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                {user.username}
              </td>
              <td className="px-6 py-4">{user._id}</td>
              <td className="px-6 py-4">{user.email}</td>
              <td className="px-6 py-4">
                {user.role}{" "}
                {show ? (
                  <>
                    <input
                      className="border px-5 py-2.5 rounded"
                      onChange={(e) => setRole(e.target.value)}
                      type="text"
                      placeholder="admin"
                      required
                    />{" "}
                    <button
                      onClick={() => updateRole(user.email, role)}
                      className="bg-red-500 text-white px-5 py-2.5 rounded"
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <button onClick={() => setShow(!show)} className="bg-red-500 text-white px-5 py-2.5 rounded">
                    edit
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
