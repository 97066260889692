import React, { useContext, useEffect, useState } from "react";
import { MdOutlineDashboard,  MdOutlineFastfood } from "react-icons/md";
import { IoBagAddOutline, IoChevronForward } from "react-icons/io5";
import { FiBox } from "react-icons/fi";
import { LuUsers2 } from "react-icons/lu";
import axios from "axios";
import { VscAccount } from "react-icons/vsc";
import { AppContext } from "../App";

export const Sidebar = ({setShowCurrentTab, currentColor, currentTab }) => {
  const {url} = useContext(AppContext)
  const [user, setUser] = useState([])
  useEffect(() => {
    const token = localStorage.getItem("token");
    const getUser = async () => {
      try {
        const user = await axios.get(`${url}api/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setUser(user.data);
        console.log(user.data);
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    getUser();
  }, []);
  return (
      <div className="fixed top-16 left-0 z-[5000] bg-white dark:bg-[#252525] h-screen w-20 md:w-60 border-r dark:border-r-[#3d3d3d] border-r-gray-100">
        <ul className="flex px-4 py-10 flex-col gap-8">
          {user?.map((u) => {
          if (u.email === localStorage.getItem("logged")) {
          if (u.role === "admin") {
         return <> 
          <li
            onClick={() => setShowCurrentTab(1)}
            className={
              currentTab === 1
                ? `${currentColor} cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2 justify-between`
                : ` cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2`
            }
          >
            <div className="flex items-center space-x-2">
              <MdOutlineDashboard className="text-2xl" />
              <p className="hidden md:block">Dashboard</p>
            </div>
            <IoChevronForward
              className={currentTab === 1 ? "flex justify-end" : "hidden"}
            />
          </li>
         <li
            onClick={() => setShowCurrentTab(2)}
            className={
              currentTab === 2
                ? `${currentColor} cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2 justify-between `
                : ` cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2`
            }
          >
            <div className="flex items-center space-x-2">
              <IoBagAddOutline className="text-2xl" />
              <p className="hidden md:block">Add Item</p>
            </div>
            <IoChevronForward
              className={currentTab === 2 ? "flex justify-end" : "hidden"}
            />
          </li>
            

          <li
            onClick={() => setShowCurrentTab(3)}
            className={
              currentTab === 3
                ? `${currentColor} cursor-pointer rounded-lg px-3 py-1 flex items-center justify-between space-x-2`
                : ` cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2`
            }
          >
            <div className="flex items-center space-x-2">
              <LuUsers2 className="text-2xl" />
              <p className="hidden md:block">Users</p>
            </div>
            <IoChevronForward
              className={currentTab === 3 ? "flex justify-end" : "hidden"}
            />
          </li>
          <li
            onClick={() => setShowCurrentTab(6)}
            className={
              currentTab === 6
                ? `${currentColor} cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2 justify-between`
                : ` cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2`
            }
          >
            <div className="flex items-center space-x-2">
            <FiBox className="text-2xl" />
              <p className="hidden md:block">Products</p>
            </div>
            <IoChevronForward
              className={currentTab === 6 ? "flex justify-end" : "hidden"}
            />
          </li>
          </>
          } else{
            return <></>
          }
          }})}
          <li
            onClick={() => setShowCurrentTab(4)}
            className={
              currentTab === 4
                ? `${currentColor} cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2 justify-between`
                : ` cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2`
            }
          >
            <div className="flex items-center space-x-2">
            <MdOutlineFastfood className="text-2xl" />
              <p className="hidden md:block">Orders</p>
            </div>
            <IoChevronForward
              className={currentTab === 4 ? "flex justify-end" : "hidden"}
            />
          </li>
          <li
            onClick={() => setShowCurrentTab(5)}
            className={
              currentTab === 5
                ? `${currentColor} cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2 justify-between`
                : ` cursor-pointer rounded-lg px-3 py-1 flex items-center space-x-2`
            }
          >
            <div className="flex items-center space-x-2">
            <VscAccount className="text-2xl" />
              <p className="hidden md:block">Account</p>
            </div>
            <IoChevronForward
              className={currentTab === 5 ? "flex justify-end" : "hidden"}
            />
          </li>
        </ul>
      </div>
  );
};
