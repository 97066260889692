import React, { useContext, useState } from "react";
import axios from "axios";
import { AppContext } from "./App";
import { useNavigate } from "react-router-dom";

export const ChangePass = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const { url } = useContext(AppContext);
  const navigate = useNavigate()
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make HTTP POST request to backend endpoint
      const response = await axios.post(`${url}api/update-password`, {
        email,
        password,
      });
      setMessage(response.data.message);
      setTimeout(() => {
        navigate('/admin')
      }, 2000)
      // Clear input fields after successful password update
      setEmail("");
      setPassword("");
    } catch (error) {
      if (error.response) {
        // Server responded with an error status (e.g., 4xx, 5xx)
        setMessage(error.response.data.message);
      } else {
        // Network error or other issues
        setMessage("Failed to update password. Please try again.");
      }
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Change Password</h1>
      <form onSubmit={handleFormSubmit}>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-600"
          >
            Email
          </label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-600"
          >
            New Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-red-500 focus:ring focus:ring-red-500 focus:ring-opacity-50"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-500 focus:ring-opacity-50"
        >
          Change Password
        </button>
      </form>
      {message && <p className="mt-4 text-red-500">{message}</p>}
    </div>
  );
};
