import React from 'react'

export const CancelRoute = () => {
  return (
    <div className="p-10 text-center">
    <h1 className="text-4xl font-bold text-black">Payment Canceled</h1>
    <p className="mt-4 text-lg">Your payment was canceled. Please try again.</p>
  </div>
  )
}
