import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const FavoriteContext = createContext();

export const FavoriteProvider = ({ children }) => {
  const [fav, setFav] = useState([]);
  const url = localStorage.getItem("url");

  const addFav = async (item, user) => {
    if (!localStorage.getItem("logged")) {
      toast.error("Please Login!");
      return;
    }

    try {
      const response = await axios.post(`${url}api/fav`, {
        item: item,
        current_user: user,
      });

      setFav((prevItems) => [...prevItems, response.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const increaseFavItem = async (id) => {
    try {
      const response = await axios.put(`${url}api/fav/increase/${id}`);
      setFav((prevItems) =>
        prevItems.map((item) => (item._id === response.data._id ? response.data : item))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const decreaseFavItem = async (id) => {
    try {
      const response = await axios.put(`${url}api/fav/decrease/${id}`);
      if (response.data.quantity <= 0) {
        await deletFavItem(id);
      } else {
        setFav((prevItems) =>
          prevItems.map((item) => (item._id === response.data._id ? response.data : item))
        );
      }
    } catch (error) {
      console.error("Error decreasing item quantity:", error);
    }
  };

  const deletFavItem = async (id) => {
    try {
      await axios.delete(`${url}api/fav/${id}`);
      setFav((prevItems) => prevItems.filter((item) => item._id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const clearFav = async (email) => {
    try {
      await axios.delete(`${url}api/fav/clear/${email}`);
      setFav([]);
    } catch (error) {
      console.error("Error clearing favorites:", error);
    }
  };

  useEffect(() => {
    const fetchFavItems = async () => {
      try {
        const response = await axios.get(`${url}api/fav/${localStorage.getItem("logged")}`);
        setFav(response.data);
      } catch (error) {
        console.error("Error fetching favorite items:", error);
      }
    };

    fetchFavItems();
  }, [url]);

  return (
    <FavoriteContext.Provider value={{ fav, addFav, increaseFavItem, decreaseFavItem, deletFavItem, clearFav }}>
      {children}
    </FavoriteContext.Provider>
  );
};

export const useFavorite = () => useContext(FavoriteContext);
