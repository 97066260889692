import React, { useEffect, useState } from 'react';
import axios from 'axios';

export const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const token = localStorage.getItem('token');
  const user = localStorage.getItem('logged');
  const url = localStorage.getItem('url')

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        if (user) {
          const response = isAdmin
            ? await axios.get(`${url}api/get-completed-order`)
            : await axios.get(`${url}api/get-one-completed-order/${user}`);

          const orderData = response.data;
          setOrders(orderData);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
        // Handle error if needed (e.g., show error message)
      }
    };

    // Determine if the user is an admin (based on role)
    const checkUserRole = async () => {
      try {
        const response = await axios.get(`${url}api/one-user/${user}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { role } = response.data;
        setIsAdmin(role === 'admin');
      } catch (error) {
        console.error('Error fetching user role:', error);
        // Default to non-admin role if user role cannot be determined
        setIsAdmin(false);
      }
    };

    // Fetch user role and orders when the component mounts
    checkUserRole();
    fetchOrders();
  }, [isAdmin, user, url, token, orders]);

  return (
    <div className="w-full h-screen bg-white px-4 py-10 dark:bg-[#252525] dark:text-white z-[5000]">
      <h2 className="text-2xl font-bold mb-4">Orders {isAdmin ? 'for All Users' : `for User: ${user}`}</h2>
      {orders.length > 0 ? (
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {orders.map((order) => (
            <li key={order._id} className="border dark:border-[#3d3d3d] rounded-lg overflow-hidden shadow-md">
              <img src={order.imageUrl} alt={order.name} className="w-full h-48 object-cover" />
              <div className="p-4">
                <p className="text-lg font-semibold mb-2">{order.name}</p>
                <p className="text-gray-600 dark:text-gray-300">${order.price}</p>
                <p className="text-gray-600 dark:text-gray-300">{order.current_user}</p>
                <p className="text-gray-600 dark:text-gray-300">x {order.quantity}</p>
                <p className="text-gray-600 dark:text-gray-300">Time: {order.createdAt}</p>
              
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-lg">No orders found{isAdmin ? '' : ' for this user'}.</p>
      )}
    </div>
  );
};
