import React, { useState, useEffect } from 'react';
import { RecentOrders } from './RecentOrders';
import { BestSellers } from './BestSellers';
import { TotalOrders } from './TotalOrders';
import Charts from './Charts';

export const Dashboard = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Function to fetch order data and update chartData
    const fetchOrderData = async () => {
      try {
        // Fetch order data from your backend API
        const response = await fetch(`http://localhost:3001/api/get-completed-order`);
        const orders = await response.json();

        // Calculate daily sales counts for each day of the week
        const dayCounts = calculateDailySales(orders);

        // Update chartData with the calculated values
        setChartData({
          labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          values: dayCounts,
          name: 'Daily Sales',
          type: 'bar',
          color: '#4dadce'
        });
      } catch (error) {
        console.error('Error fetching order data:', error);
      }
    };

    // Call fetchOrderData when component mounts
    fetchOrderData();
  }, []); // Run once on component mount

  const calculateDailySales = (orders) => {
    const dayCounts = [0, 0, 0, 0, 0, 0, 0]; // Initialize counts for each day of the week

    // Iterate through orders and count sales for each day
    orders.forEach(order => {
      const orderDate = new Date(order.createdAt); // Assuming 'timestamp' is the order date/time field
      const dayOfWeek = orderDate.getDay(); // Get day of the week (0=Sun, 1=Mon, ..., 6=Sat)
      dayCounts[dayOfWeek]++; // Increment the count for the corresponding day
    });

    return dayCounts;
  };

  return (
    <div className='h-screen grid lg:grid-cols-2 gap-6'>
      <h1 className='text-2xl'>Welcome to your dashboard, {localStorage.getItem('logged')}!</h1>
    </div>
  );
};
