import axios from 'axios';
import React, { useEffect } from 'react'

export const Blog = () => {
 
    const fetchUserProfile = async () => {
        try {
          const response = await axios.get('http://localhost:3001/medium/me');
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      };

      useEffect(() => {
        fetchUserProfile()
      }, [])
      
  return (
    <div className='text-center h-screen py-4 px-4 flex items-center justify-center flex-col'>
      <h1 className='text-7xl '>COMING SOON</h1>
      <p className='py-2 opacity-60'>Please stay tuned while we are writing a few articles for you to read. In the meantime please <a className='underline' href='/'>browse around.</a></p>
    </div>
  )
}
