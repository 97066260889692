import React, { useContext, useEffect, useState } from "react";
import data from "./data.js";
import { AppContext } from "./App.js";
import axios from "axios";
import { CiHeart } from "react-icons/ci";
import { ToastContainer, toast } from "react-toastify";
import { RiEyeLine } from "react-icons/ri";
import "react-toastify/dist/ReactToastify.css";
import Modal from "./Modal.jsx";
import { useLocation, useParams } from "react-router-dom";
import { BiCart } from "react-icons/bi";
import shadow from './assets/shadow.png'
import { CartProvider, useCart } from "./components/CartProvider.js";
import { FavoriteProvider, useFavorite } from "./components/FavoriteProvider.js";
const Home = () => {
  const { url } = useContext(AppContext);
  const {addItem} = useCart()
  const {addFav} = useFavorite()
  const [selected, setSelected] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [allItems, setAllItems] = useState([]);
  const uniqueCategories = [];
  const location = useLocation();
  const openModal = (itemId) => {
    setSelectedItem(itemId);
  };

  const closeModal = () => {
    setSelectedItem(null);
  };

  data.forEach((cat) => {
    if (!uniqueCategories.includes(cat.category)) {
      uniqueCategories.push(cat.category);
    }
  });

  useEffect(() => {
    const fetchAllItems = async () => {
      try {
        const response = await axios.get(`${url}api/allItems`);
        setAllItems(response.data);
      } catch (error) {
        console.error("Error fetching all items:", error);
      }
    };

    fetchAllItems();
  }, [url]);

  const { id } = useParams();

  useEffect(() => {
    setSelected(id || "");
  }, [id]);


  return (
    <>
      <div>
        <ToastContainer />
      </div>
      <div className=" z-50 relative text-center py-10 max-w-4xl mx-auto px-4 md:px-10 h-[500px]">
       
        <p className="bg-red-200 text-red-600 w-fit mx-auto px-3 rounded-lg my-4">
          NEW DROPS WEEKLY
        </p>
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-semibold">
          Discover Our Ready-to-Launch Website Templates
        </h1>
        <p className="py-4 md:w-3/4 md:px-10 mx-auto lg:text-xl text-gray-600 dark:text-gray-300">
          Skip hours of design and development with our collection of
          meticulously crafted templates, primed for your project's success.
        </p>
        <div className="flex shrink-0 items-center space-x-4 justify-center">
          <a className="shrink-0" href="/admin">
            <button className="bg-black dark:bg-white dark:text-black text-white px-5 py-2.5 rounded-xl">
              {localStorage.getItem("logged")
                ? "Dashboard"
                : "Create free account"}
            </button>
          </a>
          <a className="shrink-0" href="/blog">
            <button className="border dark:border-gray-500 px-5 py-2.5 rounded-xl">
              {localStorage.getItem("logged") ? "Read Blogs" : "Read Blogs"}
            </button>
          </a>
        </div>
      </div>

      <div className="mx-auto px-2 py-4">
        <h1 className="text-center text-3xl font-bold pb-4">Discover</h1>
        <div className="sticky top-24  h-fit z-10 hidescroll flex lg:justify-center space-x-2 overflow-x-scroll px-4">
          <button
            onClick={() => setSelected("")}
            className={
              `border hover:border-none border-gray-500 px-6 py-3 rounded-full ` +
              (selected === ""
                ? "text-white bg-black dark:bg-white dark:text-black hover:bg-black dark:hover:bg-white border-none"
                : "text-gray-500 bg-white dark:bg-[#252525] hover:bg-black dark:hover:bg-white dark:hover:text-black hover:text-white")
            }
          >
            All
          </button>
          {uniqueCategories.map((category, index) => (
            <button
              onClick={() => setSelected(category)}
              key={index}
              className={
                `border hover:border-none border-gray-500 px-6 shrink-0 rounded-full ` +
                (category === selected
                  ? "bg-black  dark:bg-white dark:text-black text-white hover:bg-black border-none"
                  : "text-gray-500 bg-white dark:bg-[#252525] dark:hover:bg-white dark:hover:text-black dark:text-gray-300 hover:bg-black hover:text-white")
              }
            >
              {category}
            </button>
          ))}
        </div>
        <h1 className="text-xl font-bold py-4 text-gray-600 dark:text-gray-300">Trending Products</h1>
        <div className="relative grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
          {data
            .filter((cat) => !selected || cat.category === selected)
            .map((item) => (
              <div
                key={item.id}
                className="relative group dark:drop-shadow-md rounded-lg h-[360px] p-4"
              >
                <div className="dark:hidden absolute bottom-0 left-0 rounded w-full">
                  <img className="w-full rounded" src={shadow} alt="" />
                </div>
                {item.new && 
                <div className="absolute top-4 right-0 text-white rounded-full p-2 drop-shadow-md bg-green-500">{item.new}</div>
                }
                <img
                  className="w-full group-hover:scale-105 transition-all duration-300 ease-linear h-full object-cover rounded-md mb-4"
                  src={item.imageUrl}
                  alt={item.name}
                />
                <div className="flex justify-between items-center md:pt-0">
                  <p className="font-semibold text-xl">{item.name}</p>
                  <p className="font-bold rounded-full flex items-center justify-center">
                    ${item.price}
                  </p>
                </div>
                <div className="absolute bottom-[40px] md:bottom-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:hidden group-hover:block transition-all duration-500 ease-linear">
                  <div className="flex flex-row items-center space-x-3">
                    {/* Heart Icon (Add to Favorite) */}
                    {/* <div className="p-2 bg-white dark:bg-black dark:text-white rounded-full hover:bg-gray-300 cursor-pointer">
                      <CiHeart
                        onClick={() => {
                          if (!localStorage.getItem("logged"))
                            toast.error("please login!");
                          else {
                            // notifyFav(item.name);
                            addFav(item, localStorage.getItem("logged"));
                          }
                        }}
                        className="md:text-2xl"
                      />
                    </div> */}
                    {/* Eye Icon (View Details) */}
                    <a
                      href={`/details/${item.id}`}
                      className="p-4 bg-white border-black border dark:bg-black dark:text-white rounded-full hover:bg-gray-300 cursor-pointer"
                    >
                      <RiEyeLine className="text-2xl" />
                    </a>
                    {/* Cart Icon (Add to Cart) */}
                    <button
                      onClick={() => {
                        if (!localStorage.getItem("logged")) {
                          toast.error("please login!");
                          setShowForm(true);
                        } else {
                          addItem(item, localStorage.getItem("logged"));
                        }
                      }}
                      className="rounded-full p-4 dark:bg-black dark:text-white border-black border bg-white hover:bg-gray-300 cursor-pointer"
                    >
                      <BiCart className="text-2xl" />
                    </button>
                  </div>
                </div>
                {selectedItem === item.id && (
                  <Modal isOpen={openModal} onClose={closeModal} item={item} />
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Home;
