import React from "react";
import Faqcard from "./Faqcard";


export const FAQ = () => {
  const faqs = [
    {
      question: "How does Dreamy templates work?",
      answer:
        "Simply signup and buy a template then we will send you the file to the email you signed up with. There is no monthly fees. No HIDDEN fees.",
    },
    {
      question: "How do we use it?",
      answer:
        "When you downloaded, you will get an email sent to you regarding the files to download.",
    },
    {
      question: "Do you provide refund?",
      answer:
        "No. But we do have free templates for you to test, to see if it is the right fit for you.",
    },
    {
      question:
        "Can I get custom website made for me?",
      answer:
        "Yes, contact us and we will get back to you.",
    },
    {
      question: "Can I resell the templates?",
      answer:
        "Yes, do what you wish with it. But give us the attributions.",
    },
    {
      question: "Do you help with support?",
      answer:
        "Yes, we will support you until the site is set correctly for you. That would be an extra cost. Please contact us so that we can set up a time to meet.",
    },
  ];

  return (
    <div className="px-4 max-w-3xl mx-auto text-center ">
      <h1 className="font-[500] text-[28px] lg:pt-10">
        Frequently Asked Questions
      </h1>
      <p className="py-1 text-gray-500 text-lg">
        If you have any questions, we are here to provide assistance. In case you
        haven’t received a reply, you can send us an email. We will get back to
        you within 24 hours.
      </p>
      <div className="lg:pt-6 lg:pb-3">
        {faqs.map((faq) => (
          <Faqcard data={faq} />
        ))}
      </div>
     
    </div>
  )}