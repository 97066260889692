import React, { createContext, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import { Cart } from "./Cart";
import { Nav } from "./Nav";
import { Admin } from "./AdminPage/Admin";
import { SuccessRoute } from "./SuccessRoute";
import { CancelRoute } from "./CancelRoute";
import { Favorite } from "./favorite/Favorite";
import { ResetPass } from "./ResetPass";
import { ChangePass } from "./ChangePass";
import { OwnerView } from "./Ownerview/OwnerView";
import { Plans } from "./Plans";
import { FAQ } from "./components/FAQ";
import { Footer } from "./components/Footer/Footer";
import { Detailspage } from "./Details/Detailspage";
import { AdminDashboard } from "./AdminPage/AdminDashboard";
import { DarkModeContext } from "./DarkModeContext";
import { toast } from "react-toastify";
import axios from "axios";
import { CartProvider } from "./components/CartProvider";
import { FavoriteProvider } from "./components/FavoriteProvider";
import { Blog } from "./components/Blog/Blog";
import { ContactForm } from "./components/ContactForm";

export const AppContext = createContext();
function App() {
  const [logOrSign, setLogOrSign] = useState(false);
  const [hideNav, setHideNav] = useState(false);
  const [hideFooter, setHideFooter] = useState(false);
  const [iconClicked, setIconClicked] = useState(false);
  const [userLogged, setUserLogged] = useState([]);
  const [hearts, setHearts] = useState([]);
  const [webSocketMessage, setWebSocketMessage] = useState("");
  const environment = process.env.NODE_ENV;

  useEffect(() => {
    const token = localStorage.getItem("token");
    const url = localStorage.getItem("url");

    const getUser = async () => {
      try {
        await axios.get(`${url}api/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getUser();

    if (environment === "development") {
      localStorage.setItem("url", "http://localhost:3001/");
    }
    if (environment === "production") {
      localStorage.setItem("url", "https://dreamy-vx35.onrender.com/");
    }

    // if (
    //   window.location.pathname === "/ownerview" ||
    //   window.location.pathname === "/admin"
    // ) {
    //   setHideNav(true);
    // } else {
    //   setHideNav(false);
    // }

    if (window.location.pathname === "/admin_dashboard") {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }
  }, [environment]);

  const { isDarkMode } = useContext(DarkModeContext);
  useEffect(() => {
    document.body.classList.toggle("dark", isDarkMode);
  }, [isDarkMode]);

  const values = {
    iconClicked,
    setIconClicked,
    userLogged,
    hearts,
    setHearts,
    setLogOrSign,
    logOrSign,
    url: localStorage.getItem("url"),
    toast,
    webSocketMessage,
    setWebSocketMessage,
  };

  return (
    <div className={isDarkMode && "dark:bg-[#252525] dark:text-white"}>
      <CartProvider>
        <FavoriteProvider>

        <AppContext.Provider value={values}>
          <Router>
            {hideNav ? "" : <Nav />}
            <Routes>
              <Route path="/ownerview" element={<OwnerView />} />
              <Route exact path="/" element={<Home />} />
              <Route exact path="/:id" element={<Home />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/reset-password/:id" element={<ChangePass />} />
              <Route path="/request-reset-password" element={<ResetPass />} />
              <Route path="/success" element={<SuccessRoute />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/cancel" element={<CancelRoute />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/admin_dashboard" element={<AdminDashboard />} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/fav" element={<Favorite />} />
              <Route path="/contact-us" element={<ContactForm />} />
              <Route path="/details/:id" element={<Detailspage />} />
            </Routes>
          </Router>
          {hideFooter ? "" : <Footer />}
        </AppContext.Provider>
        </FavoriteProvider>
      </CartProvider>
    </div>
  );
}

export default App;
