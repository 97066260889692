import { useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { AppContext } from './App';
import { toast, ToastContainer } from 'react-toastify';

const Modal = ({ isOpen, onClose, title, item }) => {
  const overlayClasses = isOpen ? 'fixed top-0 left-0 bg-gray-800 bg-transparent z-[50000] flex justify-center items-center' : 'hidden';
  const modalClasses = isOpen ? 'bg-white p-4 rounded-lg  w-full m-4 h-full' : 'hidden';
  const { addItem} = useContext(AppContext);

  const notify = (item) => {
    toast.success(`you added ${item} to cart`)
  }
  return (
    <div className={overlayClasses}>
        <ToastContainer/>
      <div className={modalClasses}>
        <div className='fixed inset-0 bg-black/40 -z-10'></div>
        <div className="relative ">
          <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
          <button
            className="absolute -top-6 bg-red-500 p-2 rounded-full  -right-6 text-white hover:bg-red-600 focus:outline-none"
            onClick={onClose}
            aria-label="Close Modal"
          >
            <AiOutlineClose className="w-5 h-5" />
          </button>
        </div>
        <div className='h-[400px]  flex flex-col items-center md:flex-row space-x-4'>
            <img className='my-4 w-full mx-auto  object-cover' src={item.imageUrl} alt="" />
            <div>
            <p className='font-bold text-3xl'>{item.name}</p>
            <p className='text-4xl py-2 text-red-500'>${item.price}</p>
            <p className='lg:text-xl'>Priyoshop has brought to you the Hijab 3 Pieces Combo Pack PS23. It is a completely modern design and you feel comfortable to put on this hijab. Buy it at the best price.</p>
            <div className='py-4 flex space-x-3 items-center'>
                <button onClick={() => {
                    addItem(item)
                    notify(item.name)
                    }} className='bg-red-500 px-5 py-2.5 rounded-full text-white'>Add To Cart</button>
            </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
