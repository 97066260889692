import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";

export const WriteReview = ({ productId }) => {
  const [text, setText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [userName, setUserName] = useState('');
  const [rating, setRating] = useState(0);
  const userId = localStorage.getItem("logged");
  const [reviews, setReviews] = useState([]);
  const token = localStorage.getItem('token');
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `http://localhost:3001/api/addReview/${productId}`,
        {
          text,
          rating,
          userId,
        }
      );
      console.log("Review created:", response.data);
      closeModal();
      // Optionally, you can update the UI to indicate success
    } catch (error) {
      console.error("Error creating review:", error);
      // Handle error
    }
  };

  // Function to open the modal
  const openModal = () => {
    setIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleHover = (hoveredRating) => {
    setRating(hoveredRating);
  };

  const handleClick = (clickedRating) => {
    if (rating === clickedRating) {
      // If the clicked star is already selected, deselect it
      setRating(0);
    } else {
      // Otherwise, set the rating to the clicked star
      setRating(clickedRating);
    }
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar
        key={index}
        className={index < rating ? "text-orange-500" : "text-gray-500"}
      />
    ));
  };

  // Sum the ratings
  const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);

  // Calculate the average rating
  const averageRating = totalRating / reviews.length;

  useEffect(() => {
 
    // Fetch reviews for the product
    const fetchReviews = async () => {
     try {
       const response = await axios.get(`http://localhost:3001/api/getReviews/${productId}`);
       setReviews(response.data.review);
       console.log(response.data.review);
     } catch (error) {
       console.error("Error fetching reviews:", error);
     }
   };
   
   fetchReviews()
  }, [])

  return (
    <div className="py-8 lg:w-[32%]">
      <div className="flex justify-between items-center ">
        <h1 className="font-semibold underline">Review & Rating</h1>
        <button
          onClick={() => setIsOpen(true)}
          className="bg-gray-200 dark:text-black rounded-full text-center px-6"
        >
          Write a review
        </button>
      </div>
     
      <div className="py-6 flex justify-between items-center">
        <div>
          <div className="flex items-center space-x-2">
            <p>{averageRating ? averageRating : ''}</p>
            <p className="bg-orange-500 text-white dark:text-gray-200 rounded-full px-2 w-fit">
              {averageRating >= 4 ? "very good" : "good" || averageRating === 0 ? '' : ''}
            </p>
          </div>
          <div className="flex items-center space-x-2 py-3">
            {renderStars(Math.floor(averageRating))}
          </div>
          <p className="font-semibold">{reviews.length} ratings</p>
        </div>
      </div>
      <div
        id="for_filtering_review_out_of_howmanystar"
        className="flex items-center space-x-2"
      >
        <div className="flex items-center space-x-2 bg-gray-200 dark:text-black w-fit px-2 rounded">
          <FaStar className="text-orange-500" />
          <p>5</p>
        </div>
        <div className="flex items-center space-x-2 bg-gray-200 dark:text-black w-fit px-2 rounded">
          <FaStar className="text-orange-500" />
          <p>4</p>
        </div>
        <div className="flex items-center space-x-2 bg-gray-200 dark:text-black w-fit px-2 rounded">
          <FaStar className="text-orange-500" />
          <p>3</p>
        </div>
        <div className="flex items-center space-x-2 bg-gray-200 dark:text-black w-fit px-2 rounded">
          <FaStar className="text-orange-500" />
          <p>2</p>
        </div>
        <div className="flex items-center space-x-2 bg-gray-200 dark:text-black w-fit px-2 rounded">
          <FaStar className="text-orange-500" />
          <p>1</p>
        </div>
      </div>
      {reviews.map((review) => {
        if (review.productId == productId) {
          return (
            <div className="">
              <div className="py-4">
                <div className="flex items-center space-x-2">
                  <img
                    className="w-5 h-5 rounded-full object-cover"
                    src="https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&w=600"
                    alt=""
                  />
                  <div>
                    <h1 className="font-semibold text-sm">{review.userId.split('@')[0]}</h1>
                    <div className="flex items-center space-x-2 text-sm">
                      {renderStars(Math.round(review.rating))}
                    </div>
                  </div>
                </div>
                <p className=" text-gray-400">{review.text}</p>
              </div>
            </div>
          );
        } 
        
      })}
    
      <div>
        {/* Review Modal */}
        {isOpen && (
          <div className="z-[9000] w-full h-screen bg-black/40 fixed inset-0 flex items-center justify-center">
            <div className="bg-white relative p-6 rounded-lg w-full max-w-md">
              <p className="font-semibold">Tell us how you feel!</p>
              <form onSubmit={handleSubmit}>
                <div className="py-6 ">
                  <div className="flex justify-between">
                    <p className="text-gray-400 text-sm">Not satisified</p>
                    <div className="flex space-x-2">
                      {[...Array(5)].map((_, index) => (
                        <FaStar
                          key={index}
                          className={
                            index < rating ? "text-orange-500" : "text-gray-500"
                          }
                          onClick={() => handleClick(index + 1)}
                        />
                      ))}
                    </div>
                    <p className="text-gray-400 text-sm">Satisfied</p>
                  </div>
                  <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    className="border my-4 border-gray-300 rounded p-2 w-full"
                    placeholder="Write your review..."
                    required
                  ></textarea>
                  <div className="flex justify-between">
                    {/* Close button */}
                    <button
                      type="button"
                      onClick={closeModal}
                      className="absolute top-2 right-2 font-bold text-xl px-4 py-2 rounded"
                    >
                      x
                    </button>
                    {/* Submit button */}
                    <button
                      type="submit"
                      className="bg-black text-white px-4 py-2 rounded-full"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
