import React, { useContext } from "react";
import { AppContext } from "../App";

export const Profile = () => {
  const { iconClicked } = useContext(AppContext);
  return (
    <div className={iconClicked ? "block" : "hidden"}>
      <div className={"absolute top-[70px]  rounded-b right-0 dark:bg-[#121212] border dark:border-black/40 drop-shadow-md bg-white w-80 p-4 z-[8000]"}>
        <p className="py-2">{localStorage.getItem("logged")}</p>
        {localStorage.getItem("logged") ? (
          <>
            <a
              href="/"
              onClick={() => {
                localStorage.removeItem("token");
                localStorage.removeItem("logged");
              }}
            >
              <button className=" bg-black text-white px-5 mb-2 py-2.5 shrink-0 rounded-xl w-full">
                Logout
              </button>
            </a>
            <a href="/admin">

            <button className="dark:bg-white dark:text-black bg-gray-200 px-5 py-2.5 shrink-0 rounded-xl w-full">
              Dashboard
            </button>
            </a>
            <hr className="my-4" />
            <ul className="flex flex-col gap-4 font-semibold">
              <li>
                <a href="/faq">FAQs</a>
              </li>
              <li>
                <a href="/faq">Careers</a>
              </li>
              <li>
                <a href="/faq">Blog</a>
              </li>
            </ul>
          </>
        ) : (
          <div className="bg-white dark:bg-transparent font-semibold gap-4 flex flex-col drop-shadow-xl rounded-xl p-4">
            <ul className="flex flex-col gap-4">
              <li>
                <a href="/admin">
                  <button className="lg:hidden dark:bg-white dark:text-black bg-gray-200 px-5 py-2.5 shrink-0 rounded-xl w-full">
                    Login
                  </button>
                </a>
              </li>
              <li>
                <a href="/admin">
                  <button className="lg:hidden bg-black px-5 py-2.5 shrink-0 rounded-xl text-white w-full">
                    Create free account
                  </button>
                </a>
              </li>
              <hr className="lg:hidden" />
              <li>
                <a href="/faq">FAQs</a>
              </li>
              <li>
                <a href="/faq">Careers</a>
              </li>
              <li>
                <a href="/faq">Blog</a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
