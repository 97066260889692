import React from 'react'
import ProfileForm from './Profile/ProfileForm'

export const Account = () => {
  return (
    <div>
        <ProfileForm/>
    </div>
  )
}
