import React from "react";
import whitelogo from "../../assets/whitelogo.png";
import darklogo from "../../assets/darklogo.png";

import {
  FaTwitter,
  FaDribbble,
  FaLinkedin,
  FaFigma,
  FaYoutube,
  FaFacebook,
  FaTiktok,
  FaInstagram,
} from "react-icons/fa";
export const LeftSide = () => {
  return (
    <div className="py-6 w-auto">
      <a href="/">
        <img className="dark:hidden w-40" src={darklogo} alt="" />
        <img className="hidden dark:block w-40" src={whitelogo} alt="" />
      </a>
      <p className="py-4 w-full md:w-1/2">
        A constantly evolving platform of user-friendly site that empowers
        designers to craft meaningful stunning websites.
      </p>
     <div className="flex items-center space-x-4 text-4xl text-white">
      <a  rel="noreferrer" href="https://www.instagram.com/dreamysite21?igsh=cXIyanEyb2lhYWd4&utm_source=qr" target="_blank">
      <FaInstagram className="bg-black dark:bg-white dark:text-black p-2 rounded-full"/>
      </a>
      <a  rel="noreferrer" href="https://www.tiktok.com/@dreamysite21?lang=en" target="_blank">
      <FaTiktok className="bg-black dark:bg-white dark:text-black p-2 rounded-full"/>
      </a>
      <a  rel="noreferrer" href="https://x.com/dreamySite21" target="_blank">
      <FaTwitter className="bg-black dark:bg-white dark:text-black p-2 rounded-full"/>
      </a>
      <a  rel="noreferrer" href="https://www.youtube.com/channel/UCD8z2jDcHE9B1qI7iBEqjiA" target="_blank">
      <FaYoutube className="bg-black dark:bg-white dark:text-black p-2 rounded-full"/>
      </a>
      <a  rel="noreferrer" href="https://www.facebook.com/profile.php?id=61553767455416" target="_blank">
      <FaFacebook className="bg-black dark:bg-white dark:text-black p-2 rounded-full"/>
      </a>
     </div>
    </div>
  );
};
