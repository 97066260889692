import React from "react";
import { CiHeart } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
import { useCart } from "../components/CartProvider";
import { toast } from "react-toastify";
export const Price = ({ data }) => {
  const { addItem } = useCart();

  return (
    <div className="bg-gray-100 dark:text-black sticky top-0 rounded-xl px-4 py-2 w-80 h-full">
      <p className="font-bold">${data.price}</p>
      <button
            onClick={() => {
              if (!localStorage.getItem("logged")) {
                alert("please login!");
              } else {
                addItem(data, localStorage.getItem("logged"));
              }
            }}
        className="bg-black text-white my-4 rounded-full px-5 py-2.5 shrink-0 w-full "
      >
        Add to Cart
      </button>
      <div className="flex items-center justify-between">
        {/* <div
          onClick={() => addFav(data, localStorage.getItem("logged"))}
          className="cursor-pointer flex items-center space-x-2"
        >
          <CiHeart />
          <p>Add to wishlist</p>
        </div> */}
        <a
          rel="noreferrer"
          href={data.liveSite}
          target="_blank"
          className="cursor-pointer flex items-center space-x-2"
        >
          <IoEyeOutline />
          <p>Live Site</p>
        </a>
      </div>
    </div>
  );
};
