import React, { useState } from "react";

export const Images = ({ data }) => {
  const [images, setImages] = useState("");

  const handleClick = (id) => {
    setImages(id);
  };
  return (
    <div className="w-1/2 rounded-lg">
      {!images ? (
        <img
          className="w-full h-80 md:h-96 lg:h-full rounded-lg object-cover"
          src={data.imageUrl}
          alt=""
        />
      ) : (
        <img
          className="w-full h-80 md:h-96 lg:h-full rounded-lg object-cover"
          src={images}
          alt=""
        />
      )}
      {/* <div className="flex space-x-2 py-2">
        {data.childrenImgs.map((child) => (
          <>
            <img
              onClick={() => handleClick(child)}
              className="w-full lg:w-24 h-20 rounded object-cover"
              src={child}
              alt=""
            />
          </>
        ))}
      </div> */}
    </div>
  );
};
