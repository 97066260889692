import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { BsChatLeftText } from "react-icons/bs";
import { CiShoppingCart } from "react-icons/ci";
import axios from "axios";
export const Descriptions = ({ data, productId }) => {

  const [reviews, setReviews] = useState([])
  const [orders, setOrders] = useState([])
  const averageRating =
    reviews.length > 0
      ? reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length
      : 0;

  // Function to render stars
  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <FaStar
        key={index}
        className={index < rating ? "text-orange-500" : "text-gray-500"}
      />
    ));
  };



  useEffect(() => {
 
    // Fetch reviews for the product
    const fetchReviews = async () => {
     try {
       const response = await axios.get(`http://localhost:3001/api/getReviews/${productId}`);
       setReviews(response.data.review);
       // console.log(response.data.review);
     } catch (error) {
       console.error("Error fetching reviews:", error);
     }
   };

   const fetchOrders = async () => {
    try {
      const response = await axios.get(`${localStorage.getItem('url')}api/get-completed-order`)
      console.log('sadfas',response)
      setOrders(response.data)
    } catch (error) {
      console.log(error)
    }
   }

   fetchOrders()
   fetchReviews()

   }, [])

    const aggregatedOrders = orders.reduce((acc, order) => {
     if (acc[order.id]) {
       acc[order.id] += order.quantity;
     } else {
       acc[order.id] = order.quantity;
     }
     return acc;
   }, {});
 
     // Step 2: Get the total quantity for the productId being viewed
     const totalQuantity = aggregatedOrders[productId] || 0;

// alert(totalQuantity)
  return (
    <div className="md:w-96">
      <h1 className="font-bold text-3xl">{data.name}</h1>
      <div className="flex lg:flex-col space-x-4 lg:space-x-0 items-center lg:items-start py-4">
        <div className="flex items-center space-x-2">
          {renderStars(Math.floor(averageRating))}
          <p className="text-orange-500">{averageRating.toFixed(1)}</p> 
        </div>

        <div className="md:flex items-center md:space-x-2">
          <div className="flex items-center space-x-2">
            <BsChatLeftText />
            <p className="text-orange-500">{reviews.length} Review</p>
          </div>
          <div className="flex items-center space-x-2">
            <CiShoppingCart className="text-2xl" />
         
              <p className="text-orange-500">{totalQuantity} Order</p>
    
          </div>
        </div>
      </div>
      <h3 className="font-semibold">Product description</h3>
      <p className="text-gray-500 dark:text-gray-300">
        {data.productDescription}
      </p>
      <h1 className="pt-4 text-2xl">Features</h1>
      <ul className="list-disc pl-4">
        {data.features.map(feature => (
          <li>{feature}</li>
        ))}
      </ul>
    </div>
  );
};
