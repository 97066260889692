import React, { useContext, useEffect, useState } from 'react'
import { SignupForm } from './signupForm'
import { AdminDashboard } from './AdminDashboard'
import { AppContext } from '../App'
import LoginForm from './LoginForm'
import { useNavigate } from 'react-router-dom'

export const Admin = () => {
const navigate = useNavigate()
const {logOrSign} = useContext(AppContext)
const [imageIndex, setImageIndex] = useState(0);

// Array of image URLs
const imageUrls = [
  'https://cdn.dribbble.com/userupload/11360078/file/original-59bac17852a891c308da8afe0786fc54.png?resize=2048x1536',
  'https://cdn.dribbble.com/userupload/4468624/file/original-eca57510ddb35c75fabb786423332262.png?resize=2048x1536',
  'https://cdn.dribbble.com/userupload/9969317/file/original-bf2276e432ef79ba08cfd7c0ae3e7fab.jpg?resize=2048x1366',
  'https://cdn.dribbble.com/users/12298831/screenshots/20283809/media/8c43f3a37158e799088d9f6db4c44c83.png?resize=1600x1200&vertical=center'
];

// Function to change the image index
const changeImage = () => {
  setImageIndex(prevIndex => (prevIndex + 1) % imageUrls.length);
};

// Effect to change image every 60 seconds
useEffect(() => {
    if(localStorage.getItem('token')){
      navigate('/admin_dashboard')
    }
  const interval = setInterval(changeImage, 6000);

  // Clean up interval on component unmount
  return () => clearInterval(interval);
}, []); // Empty dependency array ensures this effect runs only once on mount


  return (
    <div className='flex flex-col lg:flex-row items-center justify-between'>
      <div className='w-full lg:w-1/2 h-screen flex items-center justify-center'>

      {logOrSign ? 
      <LoginForm/>
      :
      <SignupForm/>
    }
    </div>
      <img
        src={imageUrls[imageIndex]}
        className='hidden lg:flex  w-full lg:w-1/2 h-screen object-cover transition-all duration-300 ease-linear'
        alt=""
      />

    </div>
  )
}
