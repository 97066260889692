import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "./App";
import axios from "axios";
import { useNavigate } from "react-router-dom";


export const SuccessRoute = () => {
  const [cartItems, setCartItems] = useState([]);
  const { url, clearCart } = useContext(AppContext);
const navigate = useNavigate()
  const loggedUser = localStorage.getItem("logged");
  // Use useEffect to fetch data when the component mounts
  useEffect(() => {
    // Define an async function to fetch data
    const fetchData = async () => {
      try {
        // Make a GET request to the backend endpoint
        const response = await axios.get(`${url}api/cart/${loggedUser}`);
        // Update the component state with the fetched data
  
          setCartItems(response.data);
  
      } catch (error) {
        // Handle errors
        console.error("Error fetching data:", error);
      }
    };

    // Call the async function to fetch data
    fetchData();
    // Define an async function to fetch data
    
    setTimeout(() => {
      clearCart(loggedUser)
      navigate('/')
    }, 3000)
    

  }, [cartItems, url, loggedUser]); // Empty dependency array ensures the effect runs only once when the component mounts

  // const totalPrice = () => {
  //   const sum = cartItems.reduce((accumulator, currentValue) => {
  //     // Multiply price by quantity for each item and add it to the accumulator
  //     return accumulator + currentValue.price * currentValue.quantity;
  //   }, 0);

  //   // Round the sum to two decimal places
  //   const roundedSum = sum.toFixed(2);

  //   return roundedSum;
  // };

  return (
    <div className="p-10 text-center">
      <h1 className="text-4xl font-bold text-black">Payment Successful!</h1>
      <p className="mt-4 text-lg">Thank you for your purchase.</p>
      <div className="p-10">
      <h1 className="text-center text-4xl font-bold underline">Completed Order</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {cartItems?.map((item) => (
          <div
            key={item._id}
            className="flex items-center  justify-between w-full bg-gray-100 m-2 p-4 relative"
          >
            <img
              className="w-60 object-cover h-20 rounded"
              src={item.imageUrl}
              alt=""
            />
            <div>

            <h1>{item.name}</h1>
           
            <p>x {item?.quantity}</p>
            <p className="font-bold">${item.price}</p>
            </div>
            
          </div>
        ))}
      </div>
    </div>
    </div>
  )
}
