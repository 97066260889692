import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProfileForm = () => {
  const userId = localStorage.getItem('logged');
  const token = localStorage.getItem('token');
  const [message, setMessage] = useState('')
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    profilePicture: null,
  });

  // Fetch user data from backend on component mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`http://localhost:3001/api/one-user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = response.data;
        setFormData({
          email: userData.email,
          password: '', // Avoid populating password field for security reasons
          name: userData.username,
          profilePicture: null,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId, token]);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'profilePicture' ? files[0] : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Prepare data for the update request
      const updatedUserData = {
        email: formData.email,
        password: formData.password,
        username: formData.name,
        // Add other fields to update as needed (e.g., profilePicture)
      };

      // Make a POST request to update user data
      const response = await axios.post(`http://localhost:3001/api/update-user/${userId}`, updatedUserData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setMessage(response.data.message);
      setTimeout(() => {
        setMessage('')
      }, 2000)
      // Optionally display success message or perform other actions upon successful update
    } catch (error) {
      console.error('Error updating user data:', error);
      // Handle error (e.g., display error message)
    }
  };

  return (
    <div className=" bg-white dark:bg-transparent dark:border dark:border-[#3d3d3d] rounded-xl shadow-md px-8 py-6 lg:w-1/2">
      <h2 className="text-2xl font-semibold mb-4 text-black dark:text-white">Edit Profile</h2>
      <form onSubmit={handleSubmit}>
        {/* Email */}
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-white">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-700 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm dark:bg-transparent py-3 dark:text-white dark:border dark:border-[#3d3d3d] px-4"
            required
            readOnly // Prevent editing email field
          />
        </div>

        {/* Name */}
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-white">
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-700 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm dark:bg-transparent py-3 dark:text-white dark:border dark:border-[#3d3d3d] px-4"
            required
          />
        </div>
            {/* Password (optional) */}
            <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-white">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="mt-1 block w-full border-gray-700 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-sm dark:bg-transparent py-3 dark:text-white dark:border dark:border-[#3d3d3d] px-4"
          />
        </div>


        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-black text-white py-2 px-4 rounded-xl hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
        >
          Save Changes
        </button>
   
          {message && <p className='text-red-500'>{message}</p>}
       
      </form>
    </div>
  );
};

export default ProfileForm;
