import React, { useState } from "react";
import { CiCircleCheck } from "react-icons/ci";
import plansData from "./plansdata";
import axios from "axios";
export const Plans = () => {
  const [plan, setPlan] = useState(false);
  const togglePlan = () => {
    setPlan(!plan);
  };

  const handlePayment = async (paymentMethod) => {
    try {
        const res = await axios.post('http://localhost:3001/create-subscription', {customerId: 'winaung26@outlook.com',priceId: paymentMethod})
        console.log('response',res)
    } catch (error) {
        console.log(error)
    }
  }
  return (
    <div className="p-2">
      <div className="max-w-5xl mx-auto py-4">
        <h1 className="text-4xl text-center max-w-3xl mx-auto">
          Unleash your full potential and start designing better user
          experiences with full-access
        </h1>
        <div className="py-4 flex items-center justify-center space-x-4">
          <p>Monthly</p>
          {/* <div
            onClick={() => togglePlan()}
            className="cursor-pointer w-10 rounded-full border border-black   p-[2px]"
          >
            <div
              className={
                plan
                  ? "translate-x-4 w-4 h-4 rounded-full border border-black "
                  : "w-4 h-4 rounded-full border border-black "
              }
            />
          </div>
          <p>Yearly</p>
          <div className="bg-gray-100 dark:text-gray-600 px-4 py-2 rounded-lg">
            <p>Save 35%</p>
          </div> */}
        </div>
      </div>
      <div className="max-w-5xl mx-auto px-4 dark:text-gray-700 text-white">
        <div className="flex flex-col gap-4 lg:flex-row justify-center py-8">
          {plansData.map((plans) => (
            <div className={`${plans.bg} p-4 rounded-2xl w-full lg:w-fit`}>
              <p className="w-fit border-2 px-5 py-2.5 border-black rounded-lg">
                {plans.type}
              </p>

              <div className=" py-2 flex items-center space-x-1">
                {plan ? (
                  <>
                    <p className="font-bold text-4xl text-black">
                      ${plans.pricingYearly}
                    </p>
                    <p>You save $5</p>
                  </>
                ) : (
                  <>
                    <p className="font-bold text-4xl text-black">
                      ${plans.pricing}
                    </p>
                    <span>/ per month</span>
                  </>
                )}
              </div>

              <p className="pb-2 xl:w-[478px]">{plans.description}</p>
              {plans.details.map((detail) => (
                <div className="flex items-center space-x-2 pb-2">
                  <CiCircleCheck />
                  <p>{detail}</p>
                </div>
              ))}
              <button onClick={() => handlePayment(plans.price_lookup)}
                className="bg-black text-white dark:!bg-black w-full py-2.5 px-5 rounded"
              >{plans.action}</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};